import React, { lazy } from "react";
import { ADMIN_ROLE_SLUG, BRIDGER_ROLE_SLUG } from "./config";
import { hasUserAccessFunctionality } from "utils/PermissionHelper";
// signin pages
const LoginPage = lazy(() => import("pages/Login"));

// admin routes
const DashboardPage = lazy(() => import("pages/Dashboard"));
const ViewDashboard = lazy(() => import("pages/Dashboard/DetailDashboard"));
//notification
const NotificationPage = lazy(() => import("pages/Notifications"));
//resident export
const ExportResidentPage = lazy(() => import("pages/ResidentReport"));
//export audit log
const ExportAuditPage = lazy(() => import("pages/AuditLog"));
//resident
const ResidentListPage = lazy(() => import("pages/Resident"));
const CreateResidentPage = lazy(() => import("pages/Resident/AddResident"));
const EditResidentPage = lazy(() => import("pages/Resident/EditResident"));
//mobile search
const MobileSerchResident = lazy(() => import("pages/MobileSideSearch"));
const AdminDashboardPage = lazy(() => import("pages/AdminDashboard"));
const CreateResident = lazy(() => import("pages/CreateResident"));
//create user
const CreateUser = lazy(() => import("pages/CreateUser"));
const AddCreateUser = lazy(() => import("pages/CreateUser/AddUser"));
const EditCreateUser = lazy(() => import("pages/CreateUser/EditUser"));
//trems and use
const TermsAndUse = lazy(() => import("pages/TermsOfUse"));
//create block
const BlockList = lazy(() => import("pages/Block"));
const AddBlock = lazy(() => import("pages/Block/AddBlock"));
const EditBlock = lazy(() => import("pages/Block/EditBlock"));
//admin resident
const EditAdminResident = lazy(() =>
  import("pages/AdminResident/EditAdminResident")
);
//announcement
const AnnouncementList = lazy(() => import("pages/Announcement"));
const AddAnnouncement = lazy(() =>
  import("pages/Announcement/AddAnnouncement")
);
const EditAnnouncement = lazy(() =>
  import("pages/Announcement/EditAnnouncement")
);

export const mainRoutes = {
  // bridger pages.....
  dashboard: {
    path: "/",
    element: <DashboardPage />,
    allowedRoles: [BRIDGER_ROLE_SLUG, ADMIN_ROLE_SLUG],
  },
  notification: {
    path: "/notification",
    element: <NotificationPage />,
    allowedRoles: [BRIDGER_ROLE_SLUG],
  },
  mobileSerch: {
    path: "/search-result",
    element: <MobileSerchResident />,
    allowedRoles: [BRIDGER_ROLE_SLUG],
  },
  viewDashboard: {
    path: "/view-dashboard/:id",
    element: <ViewDashboard />,
    allowedRoles: [BRIDGER_ROLE_SLUG],
  },
  residentList: {
    path: "/resident-list",
    element: <ResidentListPage />,
    allowedRoles: [ADMIN_ROLE_SLUG, BRIDGER_ROLE_SLUG],
  },
  adminDashboard: {
    path: "/admin-dashboard",
    element: <AdminDashboardPage />,
    allowedRoles: [ADMIN_ROLE_SLUG],
  },
  exportResident: {
    path: "/export-report",
    element: <ExportResidentPage />,
    allowedRoles: [ADMIN_ROLE_SLUG],
  },
  exportAuditLog: {
    path: "/export-audit-log",
    element: <ExportAuditPage />,
    allowedRoles: [ADMIN_ROLE_SLUG],
  },
  createResident: {
    path: "/create-resident",
    element: <CreateResidentPage />,
    allowedRoles: [ADMIN_ROLE_SLUG, BRIDGER_ROLE_SLUG],
  },
  editResident: {
    path: "/edit-resident/:id",
    element: <EditResidentPage />,
    allowedRoles: [ADMIN_ROLE_SLUG, BRIDGER_ROLE_SLUG],
  },
  //user
  createUser: {
    path: "/create-user",
    element: <CreateUser />,
    allowedRoles: [ADMIN_ROLE_SLUG],
  },
  addCreateUser: {
    path: "/add-create-user",
    element: <AddCreateUser />,
    allowedRoles: [ADMIN_ROLE_SLUG],
  },
  editCreateUser: {
    path: "/edit-create-user/:id",
    element: <EditCreateUser />,
    allowedRoles: [ADMIN_ROLE_SLUG],
  },
  //block
  blockList: {
    path: "/block-list",
    element: <BlockList />,
    allowedRoles: [ADMIN_ROLE_SLUG],
  },
  addBlock: {
    path: "/add-block",
    element: <AddBlock />,
    allowedRoles: [ADMIN_ROLE_SLUG],
  },
  editBlock: {
    path: "/edit-block/:id",
    element: <EditBlock />,
    allowedRoles: [ADMIN_ROLE_SLUG],
  },
  //admin resident
  // adminResidentList: {
  //   path: "/resident-list",
  //   element: <ResidentListPage />,
  //   allowedRoles: [ADMIN_ROLE_SLUG, BRIDGER_ROLE_SLUG],
  // },
  
  //announcement
  announcementList: {
    path: "/announcement-list",
    element: <AnnouncementList />,
    allowedRoles: [ADMIN_ROLE_SLUG],
  },
  addAnnouncement: {
    path: "/add-announcement",
    element: <AddAnnouncement />,
    allowedRoles: [ADMIN_ROLE_SLUG],
  },
  editAnnouncement: {
    path: "/edit-announcement/:id",
    element: <EditAnnouncement />,
    allowedRoles: [ADMIN_ROLE_SLUG],
  },
};
export const loginRoutes = {
  login: { path: "/login", element: <LoginPage /> },
};
export const termsAndUserRoutes = {
  termsAndUse: { path: "/terms-and-use", element: <TermsAndUse /> },
};
export const getUserHomePageRoute = () => {
  if (hasUserAccessFunctionality("admin_login")) {
    return mainRoutes.adminDashboard.path;
  }
  else if (hasUserAccessFunctionality("bridger_login")) {
    return(mainRoutes.dashboard.path);
  }
  else {
    return mainRoutes.dashboard.path;
  }
};
