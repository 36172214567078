import React from "react";
import { userInfoSelector } from "components/_selectors/authSelectors";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarLinks from "./NavbarLinks";
import { AppWhileLogo } from "../Icons";
import { checkActiveRoute } from "utils/CommonHelper";

import { hasAdminRole } from "utils/StorageHelper";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { checkActiveRoute } from "utils/CommonHelper";
import { useTranslation } from "react-i18next";
import { hasBridgerRole } from "utils/StorageHelper";
import { switchUserRole, stopDelegation } from "store/actions/AuthAction";
import { getUserInfo } from "utils/StorageHelper";
import { getUserHomePageRoute } from "config/routing";
import { hasDelegateAccessSelector } from "components/_selectors/authSelectors";
import { t } from "i18next";
import {
  mainRoutes as routes,
  loginRoutes,
  dealerRoutes,
  subDealerRoutes,
  techniciansRoutes,
  reportRoutes,
} from "config/routing";

function Sidebar(props) {
  // let pathname = location.pathname;
  // const userHasBridgerRole = hasBridgerRole();
  const DelegateAccess = hasDelegateAccessSelector();

  const location = useLocation();
  let pathname = location.pathname;
  const userHasAdminRole = hasAdminRole();
  const userHasBridgerRole = hasBridgerRole();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { userInfo } = props;

  const delegate = () => {
    stopDelegation();
    navigate(getUserHomePageRoute());
  };
  const switchRole = () => {
    switchUserRole();
    navigate(getUserHomePageRoute());
  };
  if (userInfo && Object.keys(userInfo).length > 0) {
    return (
      <>
        {/* <!--Desktop Sidebar Start--> */}
        <nav
          id="sidebarMenu"
          class="col-md-2 col-lg-2 d-none d-md-block menu-bg p-0 navbar-vertical bg-primery"
        >
          <div class="d-none d-md-block text-center text-white py-2 border-secondary">
            <div className="col-md-2 col-lg-2 position-fixed top-0 z-1 bg-primery py-2">
            <img src={AppWhileLogo} />
            <br />
            <label class="title">
              {userInfo.user.role.name} {t("login")}
            </label>
            <br />
            <span className="text-break">{userInfo.user.email}</span>
            {userHasBridgerRole && DelegateAccess && (
              <div class="nav-item  py-2 mobile-btn mx-2 px-2 tx-font-sansserif">
                <a
                  onClick={delegate}
                  className={`nav-link text-light active border nav-link py-1 px-2 rounded-5 text-light
                        ${checkActiveRoute(
                          pathname,
                          [routes.adminDashboard.path],
                          false,
                          true
                        )}
                      `}
                >
                  {t("stop_delegate")}
                </a>
              </div>
            )}
            {userHasAdminRole && !DelegateAccess && (
              <div class="nav-item py-2 mobile-btn mx-2 px-2 tx-font-sansserif left-0">
                <a
                  onClick={switchRole}
                  className={`nav-link text-light active border nav-link py-1 px-2 rounded-5 text-light
                        ${checkActiveRoute(
                          pathname,
                          [routes.adminDashboard.path],
                          false,
                          true
                        )}
                      `}
                >
                  {t("switch_to_user")}
                </a>
              </div>              
            )}
          </div> 
        </div>
            <div class="col-md-2 col-lg-2 d-none d-md-block menu-bg form-group flex-column nav text-white">
              <div class="bg-primery mein-menu sticky-sidebar-desktop">
              <NavbarLinks />
              </div>            
              <div class="bg-primery-2 bottom-0 nav-link py-3 ps-2 bottom-0 text-start text-light w-auto leftpanel-bottom-updated position-fixed LeftSidbar-UpdatedBtn">
                {t("updated_on")}
              </div>    

             </div>   
             
        </nav>
        {/*// <!--Desktop Sidebar End-->*/}
      </>
    );
  } else {
    return null;
  }
}
const mapStateToProps = (state) => {
  return {
    userInfo: userInfoSelector(state),
  };
};
export default connect(mapStateToProps, {})(Sidebar);
