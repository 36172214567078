import React from "react";
import { useEffect, useState } from "react";
import { productRoutes, mainRoutes as routes } from "config/routing";
import Header from "components/_common/Header";
import Footer from "components/_common/Footer";
import { Outlet, useLocation } from "react-router-dom";
import ProtectedRoute from "routes/ProtectedRoute";
import Sidebar from "components/_common/Header/Sidebar";
import MobileMenu from "components/_common/Header/MobileMenu";
import { hasBridgerRole } from "utils/StorageHelper";
import MobileSidebar from "components/_common/Header/MobileSidebar";
export default function AppLayout(props) {
  const userHasBridgerRole = hasBridgerRole();
  const location = useLocation();
  const isSearchResultPage = location.pathname === routes.mobileSerch.path;
  return (
    <ProtectedRoute>
      {userHasBridgerRole ? <MobileSidebar /> : <MobileMenu />}
      <div class="container-fluid">
        <div class={`vh-100 row`}>
          {/* bg-primery-2 */}
          <Sidebar />
          <main
            class={`col-12 col-xs-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 col-xxl-10 layout-main ${
              userHasBridgerRole ? "bg-white" : "bg-primery-2"
            }`}
          >
            {isSearchResultPage ? "" : <Header />}
            <div
              className={`${
                !userHasBridgerRole ? "layout-content-section" : ""
              }`}
            >
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </ProtectedRoute>
  );
}
