import React, { useState, useRef, useEffect } from "react";
import { userInfoSelector } from "components/_selectors/authSelectors";
import { hasAdminRole } from "utils/StorageHelper";
import { userProfileInfoSelector } from "components/_selectors/authSelectors";
import { connect } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { loginRoutes, mainRoutes as routes } from "config/routing";
import NavbarLinks from "./NavbarLinks";
import { useTranslation } from "react-i18next";
import { logoutUser } from "store/actions/AuthAction";
import {
  AppWhileLogo,
  home,
  menu,
  person,
  search,
  english,
  chinese,
  malay,
  hokkien,
  notification,
} from "../Icons";
import { confirmAlert } from "utils/SwalAlertHelper";
import { match } from "path-to-regexp";
import { hasBridgerRole } from "utils/StorageHelper";
import { hasDelegateAccessSelector } from "components/_selectors/authSelectors";
import { getUserHomePageRoute } from "config/routing";
import { switchUserRole, stopDelegation } from "store/actions/AuthAction";
import { checkActiveRoute } from "utils/CommonHelper";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { userCurrentRoleSelector } from "components/_selectors/authSelectors";

function MobileSidebar(props) {
  const { t } = useTranslation();
  const closeButtonRef = useRef(null);
  const location = useLocation();
  let pathname = location.pathname;
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const userHasBridgerRole = hasBridgerRole();
  const DelegateAccess = hasDelegateAccessSelector();
  const userHasAdminRole = hasAdminRole();

  const [selectedLanguage, setSelectedLanguage] = useState("English");
  const [selectedLanguageLogo, setSelectedLanguageLogo] = useState(english);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // close mobile sidebar on outside click start...........
  const sidebarRef = useRef(null);
  const handleClick = (event) => {
    if (
      (sidebarRef.current && !sidebarRef.current.contains(event.target)) ||
      event.target.id == "mobilesidebarBtn" ||
      (event.target.parentElement &&
        event.target.parentElement.id == "mobilesidebarBtn")
    ) {
      toggleDropdown();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
  // close mobile sidebar on outside click end...........

  const changeLanguage = (lang, langLabel, logo) => {
    i18n.changeLanguage(lang);
    console.log(`Language changed to: ${lang}`);
    setSelectedLanguage(langLabel);
    setSelectedLanguageLogo(logo);
    setDropdownVisible(false); // Close the dropdown after selecting a language
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const closeDropDown = () => {
    if (dropdownOpen) {
      setDropdownOpen(!dropdownOpen);
    }
  };
  const toggleLanguageDropdown = (isOpen) => {
    setDropdownVisible(isOpen);
  };

  const pattern = routes.viewDashboard.path; // Assuming routes.viewDashboard.path is '/view-dashboard/:id'
  const isSearchResultPage = match(pattern, { decode: decodeURIComponent })(
    location.pathname
  );
  const { userInfo, userProfileInfo, userCurrentRole } = props;

  const confirmLogout = () => {
    confirmAlert(t("confirm_logout_msg"), () => {
      logoutUser(() => navigate(loginRoutes.login.path));
    });
  };

  const getSelectedLanguage = () => {
    return selectedLanguage;
  };
  const handleLinkClick = () => {
    setDropdownOpen(!dropdownOpen);
    if (props.closeButtonRef && props.closeButtonRef.current) {
      props.closeButtonRef.current.click();
    }
  };

  const delegate = () => {
    stopDelegation();
    navigate(getUserHomePageRoute());
  };

  const switchRole = () => {
    switchUserRole();
    navigate(getUserHomePageRoute());
  };

  const getSideDrawer = () => {
    console.log("userCurrentRole", userCurrentRole);

    if (dropdownOpen) {
      return (
        <>
          {/* mobile sidebar menu start */}
          <div
            ref={sidebarRef}
            id="menu"
            className="sidebar-menus right-menu-mobile pb-5"
            style={{ zIndex: -1, minHeight:"100%"}}
          >
            <div className="row bg-primery mx-auto text-center text-white">
              <div className="col-1 py-4">
                {" "}
                <i
                  onClick={toggleDropdown}
                  className="fa fa-times float-left "
                  aria-label="Close"
                  aria-hidden="true"
                ></i>
              </div>
              <div className="col-11 ps-0 pt-3">
                <img src={AppWhileLogo} />

                <br />
                <label className="title">
                  {userCurrentRole && userCurrentRole.name} {t("login")}
                </label>

                <br />

                <span className="text-break">{userProfileInfo.email}</span>

                <div class="nav-item m-2 py-1 mobile-btn mx-2 px-2 tx-font-sansserif">
                  {userHasBridgerRole && DelegateAccess && (
                    <a
                      onClick={delegate}
                      className={`nav-link mx-5 text-light active border nav-link py-1 px-2 rounded-5 text-light 
                          ${checkActiveRoute(
                            pathname,
                            [routes.adminDashboard.path],
                            false,
                            true
                          )}
                        `}
                    >
                      {t("stop_delegate")}
                    </a>
                  )}
                </div>

                <div class="nav-item m-2 py-1 mobile-btn mx-2 px-2 tx-font-sansserif">
                  {userHasAdminRole && !DelegateAccess && (
                    <a
                      onClick={switchRole}
                      className={`nav-link mx-5 text-light active border nav-link py-1 px-2 rounded-5 text-light 
                          ${checkActiveRoute(
                            pathname,
                            [routes.adminDashboard.path],
                            false,
                            true
                          )}
                        `}
                    >
                      {t("stop_delegate")}
                    </a>
                  )}
                </div>
              </div>
            </div>

            <div className=" p-0">
              <div class="mein-menu">
                <div class="pt-1 sidebar-menu col-12">
                  <ul class="nav flex-column sidebar-nav">
                    <li class="nav-item sidebar-nav-item m-0 px-3 py-2 active">
                      <Link
                        className="text-light"
                        onClick={() => setDropdownVisible(!dropdownVisible)}
                      >
                        <img
                          src={selectedLanguageLogo}
                          height={25}
                          width={25}
                          className="me-2"
                        />
                        {t(getSelectedLanguage())}
                        {dropdownVisible ? (
                          <i
                            // onClick={toggleDropdown}
                            className="fa fa-sort-asc float-end mx-4 mt-2"
                            aria-label="dropdown"
                            aria-hidden="true"
                          ></i>
                        ) : (
                          <i
                            // onClick={toggleDropdown}
                            className="fa fa-sort-desc float-end mx-4 mt-1"
                            aria-label="dropdown"
                            aria-hidden="true"
                          ></i>
                        )}
                      </Link>
                    </li>

                    {dropdownVisible && getSelectedLanguage() != "English" && (
                      <li class="nav-item sidebar-nav-item px-3 py-2 m-0">
                        <Link
                          className="text-light"
                          onClick={() =>
                            changeLanguage("en", "English", english)
                          }
                        >
                          <img
                            src={english}
                            height={25}
                            width={25}
                            className="me-2"
                          />
                          {t("English")}
                        </Link>
                      </li>
                    )}
                    {dropdownVisible && getSelectedLanguage() != "Chinese" && (
                      <li class="nav-item sidebar-nav-item px-3 py-2 m-0">
                        <Link
                          className="text-light"
                          onClick={() =>
                            changeLanguage("zh", "Chinese", chinese)
                          }
                        >
                          <img
                            src={chinese}
                            height={25}
                            width={25}
                            className="me-2"
                          />
                          {t("Chinese")}
                        </Link>
                      </li>
                    )}
                    {dropdownVisible && getSelectedLanguage() != "Malay" && (
                      <li class="nav-item sidebar-nav-item px-3 py-2 m-0">
                        <Link
                          className="text-light"
                          onClick={() => changeLanguage("ms", "Malay", malay)}
                        >
                          <img
                            src={malay}
                            height={25}
                            width={25}
                            className="me-2"
                          />
                          {t("Malay")}
                        </Link>
                      </li>
                    )}
                    {dropdownVisible && getSelectedLanguage() != "Hokkien" && (
                      <li class="nav-item sidebar-nav-item px-3 py-2 m-0">
                        <Link
                          className="text-light"
                          onClick={() =>
                            changeLanguage("hoc", "Hokkien", hokkien)
                          }
                        >
                          <img
                            src={hokkien}
                            height={25}
                            width={25}
                            className="me-2"
                          />
                          {t("Hokkien")}
                        </Link>
                      </li>
                    )}
                  </ul>
                  <ul>
                    <li className="nav-item sidebar-nav-item m-0 px-3 notification-bg sidebar-li-bg py-2">
                      <Link
                        onClick={handleLinkClick}
                        to={routes.notification.path}
                        className={`nav-link text-light
                    ${checkActiveRoute(
                      pathname,
                      [routes.notification.path],
                      false,
                      true
                    )}
                  `}
                      >
                        <img
                          src={notification}
                          height={25}
                          width={25}
                          className="me-2"
                        />
                        {t("notification")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-12 form-group flex-column nav position-fixed">
                <span class="sidebar-li-bg py-2">
                  <a class="nav-link text-light" onClick={confirmLogout}>
                    {" "}
                    <i class="fa fa-sign-out fs-5" aria-hidden="true"></i>{" "}
                    {t("log_out")}{" "}
                  </a>
                </span>
                <span class=" bg-primery-update p-2 text-light">
                  {t("updated_on")}
                </span>
              </div>
            </div>
          </div>
          {/* mobile sidebar menu end */}
        </>
      );
    } else {
      return null;
    }
  };

  if (userProfileInfo && userInfo && Object.keys(userInfo).length > 0) {
    return (
      <>
        {/* <!-- mobile footer menu start --> */}
        <div className="col-xs-12 col-sm-12 bg-primery d-block d-md-none">
          <div
            className={`${
              isSearchResultPage
                ? "footer-fixed d-none bg-primery"
                : "footer-fixed bg-primery"
            }`}
          >
            <footer className="lighten-2">
              <nav className="z-depth-0">
                <div className="nav-wrapper">
                  <ul className="justify text-center d-flex p-0">
                    <li className="px-1 py-2 w-100">
                      <Link to={routes.dashboard.path} onClick={closeDropDown}>
                        <img
                          src={home}
                          style={{ height: "26px", width: "24px" }}
                        ></img>
                        <p>{t("home")}</p>
                      </Link>
                    </li>
                    <li className="px-1 py-2 w-100">
                      <Link
                        to={routes.residentList.path}
                        onClick={closeDropDown}
                      >
                        <img
                          src={person}
                          style={{
                            height: "35px",
                            width: "41px",
                          }}
                        ></img>
                        <p>{t("residents")}</p>
                      </Link>
                    </li>
                    <li className="px-1 py-2 w-100">
                      <Link
                        to={routes.mobileSerch.path}
                        onClick={closeDropDown}
                      >
                        <img
                          src={search}
                          style={{
                            height: "24px",
                            width: "24px",
                          }}
                        ></img>
                        <p>{t("search")}</p>
                      </Link>
                    </li>
                    <li className="dropdown-container px-1 py-2 w-100">
                      <a /* onClick={toggleDropdown} */ id="mobilesidebarBtn">
                        <img
                          src={menu}
                          style={{ height: "24px", width: "24px" }}
                        ></img>
                        <p>{t("more")}</p>
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </footer>
            {getSideDrawer()}
          </div>
        </div>
        {/* <!-- mobile footer menu end --> */}
      </>
    );
  } else {
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    userInfo: userInfoSelector(state),
    userProfileInfo: userProfileInfoSelector(state),
    userCurrentRole: userCurrentRoleSelector(state),
  };
};

export default connect(mapStateToProps, {})(MobileSidebar);
