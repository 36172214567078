import React, { useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { mainRoutes as routes } from "config/routing";
import { useTranslation } from "react-i18next";
import { AppWhileLogo } from "../Icons";
import NavbarLinks from "./NavbarLinks";
import { confirmAlert } from "utils/SwalAlertHelper";
import { hasAdminRole } from "utils/StorageHelper";
import { logoutUser } from "store/actions/AuthAction";
import { loginRoutes } from "config/routing";
import { checkActiveRoute } from "utils/CommonHelper";
import { getUserHomePageRoute } from "config/routing";
import { connect } from "react-redux";
import { hasBridgerRole } from "utils/StorageHelper";

import { switchUserRole, stopDelegation } from "store/actions/AuthAction";
import { userInfoSelector } from "components/_selectors/authSelectors";
import { hasDelegateAccessSelector } from "components/_selectors/authSelectors";
import { userProfileInfoSelector } from "components/_selectors/authSelectors";
function MobileMenu({ userProfileInfo }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const closeButtonRef = useRef(null);
  const DelegateAccess = hasDelegateAccessSelector();

  const location = useLocation();
  let pathname = location.pathname;
  const userHasBridgerRole = hasBridgerRole();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
  };
  const confirmLogout = () => {
    // delete confirmation
    confirmAlert(t("confirm_logout_msg"), () => {
      // confirmed yes
      logoutUser(() => navigate(loginRoutes.login.path));
    });
  };
  const handleSearch = () => {
    navigate(routes.residentList.path, { state: { searchTerm } });
  };
  const delegate = () => {
    stopDelegation();
    navigate(getUserHomePageRoute());
  };
  const switchRole = () => {
    switchUserRole();
    navigate(getUserHomePageRoute());
  };

  if (userProfileInfo) {
    return (
      // <!-- Mobile Sidebar Only using admin Start-->
      <>
        <div class="col-12 col-md-2 bg-primery-2 position-fixed z-1">
          <nav class="navbar navbar-light bg-primery border-bottom-0 py-2">
            {/* <!-- Navbar toggle button (hamburger icon) --> */}
            <button
              class="navbar-toggler d-block d-md-none mx-2 border-0"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#sidebar"
              aria-controls="sidebar" 
            >
              <span class="text-white">
                <i class="fa fa-bars" aria-hidden="true"></i>
              </span>
            </button>
            <div class="input-group w-50 mx-2 d-md-none">
              <input
                class="form-control border-end-0 border rounded-pill search-btn"
                type="search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
                id="example-search-input"
              />
              <span class="input-group-append">
                <button
                  class="btn btn-outline-secondary bg-white border rounded-pill ms-n5"
                  type="button"
                  onClick={handleSearch}
                >
                  <i class="fa fa-search"></i>
                </button>
              </span>
            </div>
            <div className="input-group w-25 float-end d-md-none">
              <select
                className="border-0 h-100 p-1 rounded-4 w-75"
                onChange={changeLanguage}
                value={i18n.language}
              >
                <option value="en">{t("English")}</option>
                <option value="zh">{t("Chinese")}</option>
                <option value="ms">{t("Malay")}</option>
                <option value="hoc">{t("Hokkien")}</option>
              </select>
            </div>
          </nav>
          <div
            class="offcanvas offcanvas-start bg-primery d-md-block w-75 text-white"
            tabindex="-1"
            id="sidebar"
            aria-labelledby="sidebarLabel"
          >
            <div class="offcanvas-header">
              {/* <!-- <h5 class="offcanvas-title text-light" id="sidebarLabel">GFG Sidebar</h5> --> */}
              <div class="bg-primery mx-auto text-center text-white">
                <img src={AppWhileLogo} />
                <br />
                <label class="title">
                  {userProfileInfo.role.name} {t("login")}
                </label>
                <br />
                <span className="text-break">{userProfileInfo.email}</span>
                {userHasBridgerRole && DelegateAccess && (
                  <div class="nav-item m-2 py-2 mobile-btn mx-2 tx-font-sansserif">
                    <a
                      onClick={delegate}
                      className={`nav-link text-light active border nav-link py-1 rounded-5 text-light
                        ${checkActiveRoute(
                          pathname,
                          [routes.adminDashboard.path],
                          false,
                          true
                        )}
                      `}
                    >
                      {t("stop_delegate")}
                    </a>
                  </div>
                )}
                {hasAdminRole && !DelegateAccess && (
                  <div class="nav-item m-2 py-2 mobile-btn mx-2 px-2 tx-font-sansserif">
                    <a
                      onClick={switchRole}
                      className={`nav-link text-light active border nav-link py-1 rounded-5 text-light
                        ${checkActiveRoute(
                          pathname,
                          [routes.adminDashboard.path],
                          false,
                          true
                        )}
                      `}
                    >
                      {t("switch_to_user")}
                    </a>
                  </div>
                )}
              </div>
              <i
                ref={closeButtonRef}
                class="fa fa-times"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                aria-hidden="true"
              ></i>
            </div>
            <div class="offcanvas-body p-0">
              <NavbarLinks closeButtonRef={closeButtonRef} />
              <div class="form-group flex-column nav w-75 position-fixed bottom-0">
                <span class="bg-primery-2 py-2">
                  <a class="nav-link text-light active" onClick={confirmLogout}>
                    {" "}
                    <i class="fa fa-sign-out fs-5" aria-hidden="true"></i>{" "}
                    {t("log_out")}{" "}
                  </a>
                </span>
                <span class="bg-primery p-2">{t("updated_on")}</span>
              </div>
            </div>
          </div>
        </div>
      </>
      // <!-- Mobile Sidebar Only using admin End-->
    );
  } else {
    return null;
  }
}
const mapStateToProps = (state) => {
  return {
    userProfileInfo: userProfileInfoSelector(state),
  };
};
export default connect(mapStateToProps, {})(MobileMenu);
