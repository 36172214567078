import { hasAdminRole, hasBridgerRole } from "utils/StorageHelper";
export const hasUserAccessFunctionality = (functionalityName)=>{
    var userHasAdminRole = hasAdminRole();
    const userHasBridgerRole = hasBridgerRole();
    switch (functionalityName) {
        case "admin_login":
      return userHasAdminRole;
      break;
        case "bridger_login":
      return userHasBridgerRole;
      break;
    }
}