import { getUserInfo } from "utils/StorageHelper";

export const userInfoSelector = (state) => state.auth?.userInfo;
export const userLocationSelector = (state) => state.auth.currentLocation;
export const settingsSelector = (state) => state.auth.settings;
export const userProfileSelector = (state) => state.auth.profileData;
export const isLoggedinSelector = (state) => state.auth.userInfo?.authorization;
export const firebaseTokenSelector = (state) => state.auth.firebaseToken;
export const userProfileInfoSelector = (state) => {
  const userInfo = userInfoSelector(state);
  var userProfileInfo = null;
  if (userInfo) {
    userProfileInfo = userInfo.user;
  }
  return userProfileInfo;
};
export const hasDelegateAccessSelector = (state) => {
  const userInfo = getUserInfo();
  var hasAccess = false;
  if (userInfo) {
    hasAccess = userInfo?.user.main_role?true:false;
  }
  return hasAccess;
};
export const userCurrencySelector = (state) => {
  var settings = settingsSelector(state);
  if (isLoggedinSelector(state)) {
    if (userProfileSelector(state)) {
      return userProfileSelector(state).currency.symbol;
    }
  } else if (settings.currency && settings.currency.length > 0) {
    return settings.currency[0].symbol;
  }
  return "₹";
};
export const currencyCodeSelector = (state) => {
  var settings = settingsSelector(state);
  if (isLoggedinSelector(state)) {
    return userProfileSelector(state).currency.code;
  } else if (settings.currency && settings.currency.length > 0) {
    return settings.currency[0].code;
  }
  return "INR";
};
export const countryCodeSelector = (state) => {
  var settings = settingsSelector(state);
  // if(isLoggedinSelector(state)){
  //     return userProfileSelector(state).currency.code
  // }
  if (settings.currency && settings.currency.length > 0) {
    return settings.country[0].code;
  }
  return "IN";
};
export const languageSelector = (state) => {
  var settings = settingsSelector(state);
  if (settings.languages[0]) {
    return settings.languages[0].id;
  }
  return "1";
};
export const userCurrentRoleSelector  = (state) => {
  const roles= userAllRolesSelector(state);
  if(roles.length > 0){
    return roles[0];
  }
  else{
    return null;
  }
}
export const userAllRolesSelector = (state) => {
  const userInfo = userInfoSelector(state);
  var roles = [];
  if (userInfo) {
    roles = userInfo.user?.role;
  }
  if (roles instanceof Array) {
    return roles;
  }
  return [roles];
};

export const userAllRolesSlugSelector = (state) => {
  var roles = [];
  var allRoles = userAllRolesSelector(state);
  if (allRoles.length > 0) {
    roles = allRoles.map((value) => value?.slug);
  }

  return roles;
};
