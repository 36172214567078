import React, { useRef } from "react";
import {
  mainRoutes as routes,
  loginRoutes,
  dealerRoutes,
  subDealerRoutes,
  techniciansRoutes,
  reportRoutes,
} from "config/routing";
import { hasAdminRole } from "utils/StorageHelper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { checkActiveRoute } from "utils/CommonHelper";
import { useTranslation } from "react-i18next";
import { hasBridgerRole } from "utils/StorageHelper";
import { switchUserRole, stopDelegation } from "store/actions/AuthAction";
import { getUserInfo } from "utils/StorageHelper";
import { getUserHomePageRoute } from "config/routing";
import { hasDelegateAccessSelector } from "components/_selectors/authSelectors";

export default function NavbarLinks(props) {
  const location = useLocation();
  const DelegateAccess = hasDelegateAccessSelector();
  const navigate = useNavigate();
  const { t } = useTranslation();
  let pathname = location.pathname;
  const userHasAdminRole = hasAdminRole();
  const userHasBridgerRole = hasBridgerRole();

  const handleLinkClick = () => {
    if (props.closeButtonRef && props.closeButtonRef.current) {
      props.closeButtonRef.current.click();
    }
  };
  const switchRole = () => {
    switchUserRole();
    navigate(getUserHomePageRoute());
  };
  const delegate = () => {
    stopDelegation();
    navigate(getUserHomePageRoute());
  };

  return (
    <div class="bg-primery mein-menu pt-1 pb-5 mb-5">
      <div class="pt-1">
        <ul class="nav flex-column text-start">
          {/* {userHasBridgerRole && DelegateAccess && (
            <li class="nav-item">
              <Link
                onClick={delegate}
                to={routes.adminDashboard.path}
                className={`nav-link text-light
                        ${checkActiveRoute(
                          pathname,
                          [routes.adminDashboard.path],
                          false,
                          true
                        )}
                      `}
              >
                {t("stop_delegate")}
              </Link>
            </li>
          )} */}
          {userHasBridgerRole && (
            <>
              <li class="nav-item border-secondary border-top ">
                <Link
                  onClick={handleLinkClick}
                  to={routes.dashboard.path}
                  className={`nav-link text-light border-secondary border-top
                    ${checkActiveRoute(
                      pathname,
                      [routes.dashboard.path],
                      false,
                      true
                    )}
                  `}
                >
                  {t("dashboard")}
                </Link>
              </li>

              <li class="nav-item">
                <Link
                  onClick={handleLinkClick}
                  to={routes.residentList.path}
                  className={`nav-link text-light
                    ${checkActiveRoute(
                      pathname,
                      [routes.residentList.path],
                      false,
                      true
                    )}
                  `}
                >
                  {t("side_bar_create_resident")}
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  onClick={handleLinkClick}
                  to={routes.notification.path}
                  className={`nav-link text-light
                    ${checkActiveRoute(
                      pathname,
                      [routes.notification.path],
                      false,
                      true
                    )}
                  `}
                >
                  {t("side_bar_notification")}
                </Link>
              </li>
            </>
          )}
          {userHasAdminRole && (
            <>
              <li class="nav-item">
                <Link
                  onClick={handleLinkClick}
                  to={routes.adminDashboard.path}
                  className={`nav-link text-light border-secondary border-top
                        ${checkActiveRoute(
                          pathname,
                          [routes.adminDashboard.path],
                          false,
                          true
                        )}
                      `}
                >
                  {t("dashboard")}
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  onClick={handleLinkClick}
                  to={routes.createUser.path}
                  className={`nav-link text-light
                        ${checkActiveRoute(
                          pathname,
                          [routes.createUser.path],
                          false,
                          true
                        )}
                      `}
                >
                  {t("side_bar_create_user")}
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  onClick={handleLinkClick}
                  to={routes.blockList.path}
                  className={`nav-link text-light
                        ${checkActiveRoute(
                          pathname,
                          [routes.blockList.path],
                          false,
                          true
                        )}
                      `}
                >
                  {t("side_bar_create_block")}
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  onClick={handleLinkClick}
                  to={routes.residentList.path}
                  className={`nav-link text-light
                        ${checkActiveRoute(
                          pathname,
                          [routes.residentList.path],
                          false,
                          true
                        )}
                      `}
                >
                  {t("side_bar_create_resident")}
                </Link>
              </li>
              <li class="nav-item">
                <Link
                  onClick={handleLinkClick}
                  to={routes.announcementList.path}
                  className={`nav-link text-light
                        ${checkActiveRoute(
                          pathname,
                          [routes.announcementList.path],
                          false,
                          true
                        )}
                      `}
                >
                  {t("side_bar_annoucement")}
                </Link>
              </li>
              
              <li className="nav-item">
                <Link
                  onClick={handleLinkClick}
                  to={routes.exportResident.path}
                  className={`nav-link text-light ${checkActiveRoute(
                    pathname,
                    [routes.exportResident.path],
                    false,
                    true
                  )}`}
                >
                  {t("side_bar_export_resident")}
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={handleLinkClick}
                  to={routes.exportAuditLog.path}
                  className={`nav-link text-light ${checkActiveRoute(
                    pathname,
                    [routes.exportAuditLog.path],
                    false,
                    true
                  )}`}
                >
                  {t("side_bar_export_audit_log")}
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
    </div>
  );
}
